<template>
    <Form :model="baseInfo" :inline="true" :label-colon="true">
      <FormItem label="所属社区" style="width: 100%">
        <span slot="label" class="validator">所属社区:</span>
       <Select v-model="addArchiveUserRo.orgCode" placeholder="请选择所属社区"  style="width:200px" :disabled="baseInfo.other">
        <Option v-for="(item,index) in orgCodeList" :data-label="item.label" :value="item.value" :key="index">{{ item.label }}</Option>
       </Select>
        <Checkbox v-model="baseInfo.other" style="margin: 0 10px 0 20px" @on-change="changeOrgName">所属其他</Checkbox>
        <Input style="width:200px" :maxlength="20" v-model.trim="addArchiveUserRo.orgName" placeholder="请输入所属其他社区" :disabled="!baseInfo.other"></Input>
      </FormItem>
      <Divider style="margin:10px 0"/>
      <div class="content">
        <FormItem style="width: 32%">
          <span slot="label" class="validator">姓名:</span>
          <Input :maxlength="20" v-model.trim="baseInfo.addUserNeedyRo.name"></Input>
        </FormItem>
        <FormItem label="性别" style="width: 32%">
          <span slot="label" class="validator">性别:</span>
           <RadioGroup v-model="baseInfo.addUserNeedyRo.sex">
            <Radio label="1">男</Radio>
            <Radio label="2">女</Radio>
           </RadioGroup>
        </FormItem>
        <FormItem label="联系电话" style="width: 32%">
          <Input :maxlength="20" v-model.trim="baseInfo.mobile"></Input>
        </FormItem>
        <FormItem label="民族" style="width: 32%">
          <Select
        v-model="baseInfo.addUserNeedyRo.national"
        style="width: 200px; margin-right: 20px"
      >
        <Option
          v-for="item in nationList"
          :value="item.value"
          :key="item.value"
          >{{ item.label }}</Option
        >
      </Select>
        </FormItem>
         <FormItem label="出生日期" style="width: 32%">
          <DatePicker type="date" v-model="baseInfo.addUserNeedyRo.birthday" placeholder="请选择出生日期" style="width: 200px"></DatePicker>
        </FormItem>
         <FormItem label="联系地址" style="width: 100%">
          <Input :maxlength="50" v-model.trim="baseInfo.addUserNeedyRo.idNumAddr" style="width:calc(100% - 170px)"></Input>
        </FormItem>
         <FormItem label="紧急联系人" style="width: 32%">
          <Input :maxlength="20" v-model.trim="baseInfo.addUserContactsRo.name"></Input>
        </FormItem>
         <FormItem label="与案主关系" style="width: 32%">
          <Select
            v-model="baseInfo.addUserContactsRo.relationship"
            style="width: 200px; margin-right: 20px"
          >
            <Option :value="item.value" v-for="(item,index) in relationshipList" :key="index">{{item.label}}</Option>
          </Select>
         </FormItem>
          <FormItem label="紧急联系电话" style="width: 32%">
          <Input :maxlength="50" v-model.trim="baseInfo.addUserContactsRo.mobile" placeholder="多个号码请用逗号隔开"></Input>
        </FormItem>
        <FormItem label="教育程度" style="width: 100%">
           <RadioGroup v-model="baseInfo.addUserDetailRo.eduDegree">
            <Radio label="1">小学</Radio>
            <Radio label="2">初中</Radio>
            <Radio label="3">高中</Radio>
            <Radio label="4">专科</Radio>
            <Radio label="5">本科</Radio>
            <Radio label="6">硕士及以上</Radio>
           </RadioGroup>
        </FormItem>
        <FormItem label="婚姻状况" style="width: 100%">
           <RadioGroup v-model="baseInfo.addUserDetailRo.marital">
             <Radio label="0">未知</Radio>
            <Radio label="1">未婚</Radio>
            <Radio label="2">已婚</Radio>
            <Radio label="3">离异</Radio>
            <Radio label="4">丧偶</Radio>
            <Radio label="5">离异再婚</Radio>
            <Radio label="6">丧偶再婚</Radio>
            <Radio label="7">同居</Radio>
           </RadioGroup>
        </FormItem>
        <FormItem label="居所类别" style="width: 100%">
           <RadioGroup v-model="baseInfo.addUserHouseRo.houseType" @on-change="houseTypeChange">
            <Radio label="1">自置物业</Radio>
            <Radio label="2">租房</Radio>
            <Radio label="3">宿舍</Radio>
            <Radio label="4">其他</Radio>
           </RadioGroup>
           <Input :maxlength="20" v-model.trim="baseInfo.addUserHouseRo.houseTypeDesc" @on-change="houseTypeDescChange"></Input>
        </FormItem>
        <FormItem label="居住状况" style="width: 100%">
           <RadioGroup v-model="baseInfo.addUserHouseRo.liveType" @on-change="liveChange">
            <Radio label="1">独居</Radio>
            <Radio label="2">与亲人同居</Radio>
            <Input :maxlength="20" v-if="baseInfo.addUserHouseRo.liveType == 2" v-model.trim="baseInfo.addUserHouseRo.liveTypeDesc"></Input>
            <Radio label="3">与朋友同住</Radio>
             <Input :maxlength="20" v-if="baseInfo.addUserHouseRo.liveType == 3" v-model.trim="baseInfo.addUserHouseRo.liveTypeDesc"></Input>
           </RadioGroup>
        </FormItem>
        <FormItem label="健康状况" style="width: 100%">
           <RadioGroup v-model="baseInfo.addUserHealthRo.healthStatus" @on-change="baseInfo.addUserHealthRo.healthDetail == ''">
            <Radio label="1">健康良好</Radio>
            <Radio label="2">有疾病</Radio>
           </RadioGroup>
           <Input :maxlength="50" v-if="baseInfo.addUserHealthRo.healthStatus == '2'" v-model="baseInfo.addUserHealthRo.healthDesc" style="width: calc(100% - 400px)"></Input>
        </FormItem>
         <FormItem label="自理能力" style="width: 100%">
           <RadioGroup v-model="baseInfo.addUserHealthRo.selfCare" @on-change="selfChange">
            <Radio label="1">长期患病能自理</Radio>
            <Radio label="2">部分依赖他人</Radio>
            <Radio label="3">完全依赖他人</Radio>
            <Radio label="4">其他</Radio>
             <Input :maxlength="20" v-model.trim="baseInfo.addUserHealthRo.selfCareDesc" @on-change="selfCareDescChange"></Input>
           </RadioGroup>
        </FormItem>
        <FormItem label="请注明详细健康情况" style="width: 100%">
          <Input :maxlength="50" v-model.trim="baseInfo.addUserHealthRo.healthDetail" style="width:calc(100% - 250px)"></Input>
        </FormItem>
        <FormItem label="兴趣爱好" style="width: 100%">
          <Input :maxlength="30" v-model.trim="baseInfo.addUserDetailRo.hobby" style="width:calc(100% - 180px)"></Input>
        </FormItem>
         <FormItem label="收入来源" style="width: 100%">
           <RadioGroup v-model="baseInfo.addUserIncomeRo.incomeType" @on-change="incomeChange">
            <Radio label="1">工资</Radio>
            <Radio label="2">退休工资</Radio>
            <Radio label="3">子女/父母供养</Radio>
            <Radio label="4">低保</Radio>
            <Radio label="5">其他</Radio>
             <Input :maxlength="20" v-model.trim="baseInfo.addUserIncomeRo.incomeTypeDesc" @on-change="incomeOtherChange"></Input>
           </RadioGroup>
        </FormItem>
         <FormItem label="个人月收入" style="width: 32%">
          <Input :maxlength="20" v-model.trim="baseInfo.addUserIncomeRo.incomeMonthly"></Input>
        </FormItem>
         <FormItem label="工作/学校" style="width: 32%">
          <Input :maxlength="20" v-model.trim="baseInfo.addUserDetailRo.workUnit"></Input>
        </FormItem>
         <FormItem label="使用社会服务记录" style="width: 100%">
           <RadioGroup v-model="baseInfo.useServices"  @on-change="useServicesChange">
            <Radio label="0">无</Radio>
            <Radio label="1">曾有（请注明服务及时间）</Radio>
           </RadioGroup>
            <Input v-if="showSocialService || baseInfo.useServices == '1'" v-model.trim="baseInfo.socialServiceContent" :rows="3"  placeholder="请注明服务及时间" style="width: 80%" type="textarea" show-word-limit :maxlength="400"></Input>
        </FormItem>
      </div>
    </Form>
</template>

<script>
export default {
  props: ["baseInfo","addArchiveUserRo","orgCodeList"],
  data() {
    return {
      nationList: [],  //民族列表
      relationshipList: [],  //关系列表
      showSocialService: false,
    };
  },
 
  methods: {
    // 改变所属社区
    changeOrgName(status) {
      if(status) {
        this.addArchiveUserRo.orgCode = '';
      }else {
        this.addArchiveUserRo.orgName = '';
      }
    },
    houseTypeChange(val) {
      if(val != '4') {
        this.baseInfo.addUserHouseRo.houseTypeDesc = '';
      }
    },
    houseTypeDescChange(val) {
      this.baseInfo.addUserHouseRo.houseType = '4';
    },
    liveChange(val) {
      this.baseInfo.addUserHouseRo.liveTypeDesc = "";
    },
    // 自理能力
    selfChange(val) {
      if(val != '4') {
         this.baseInfo.addUserHealthRo.selfCareDesc = '';
      }
    },
    selfCareDescChange() {
      this.baseInfo.addUserHealthRo.selfCare = '4';
    },
    //收入来源
    incomeChange(val) {
      if(val != '5') {
        this.baseInfo.addUserIncomeRo.incomeTypeDesc = '';
      }
    },
    incomeOtherChange() {
      this.baseInfo.addUserIncomeRo.incomeType = '5';
    },
    //社会服务
    useServicesChange(val) {   
      if(val != '1') {
        this.baseInfo.socialServiceContent = "";
        this.showSocialService = false;
      }else {
         this.showSocialService = true;
      }
    },
  },
  created() {
    //获取民族
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
        dictType: "NATIONAKLITY"
      }).then(res => {
      if(res.code == 200) {
        this.nationList = res.dataList.map(item => {
          return {
            value: item.dictKey,
            label: item.dictValue
          }
        });
      }else {
        this.$Message.error({
          background: true,
          content: '获取民族信息失败'
        })
      }
    });
    //获取关系
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
        dictType: "FAMILY_RELATIONSHIP"
      }).then(res => {
      if(res.code == 200) {
        this.relationshipList = res.dataList.map(item => {
          return {
            value: item.dictKey,
            label: item.dictValue
          }
        });
      }else {
        this.$Message.error({
          background: true,
          content: '获取民族信息失败'
        })
      }
    });
  },
};
</script>
    
<style scoped lang='less'>
.content {
  .ivu-form-item{
   width: 100%;
   margin-bottom: 15px;
    .ivu-input-wrapper {
      width: 200px;
      /deep/input {
        border: 0;
        border-bottom: 1px solid #65656c;
        border-radius: 0;
      }
      /deep/input:hover,/deep/input:focus {
           border: 0;
           border-bottom: 1px solid #65656c;
           box-shadow: none;
        }
    }
  }
  }
  .validator {
    &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>